import { AppointmentStatus } from "../_enums/AppointmentStatus";

export type TableUpdates = {
    removed: [],
    added: []
}
export type TableState = {
    updates: TableUpdates;
    stale: string;
    option: string,
}
export type AuthenticationState = {
    loggedIn: User;
    user: User
}
export type User = {
    tokenExpiry: string;
    assetId: any;
    id: number,
    name: string,
    activeTenant: TenantData;
    tenantUserId: number;
}

export type TenantUser = {    
    user: User;
    assetId?: number;
    id: number;
    userId: number,
}

export type TenantData = {
    id: number,
    role: Role;
    assetId?: number;
}

export enum Role {
    None,
    JourneyCreator,
    ContentEditor,
    TenantManager,
    TenantAdmin,
    JourneyUser,
    BrandAmbassador,
    ShopAssistant,
    Api
}

export type State = {
    table: TableState,
    authentication: AuthenticationState
}

export type Tenant = {
    name: string;
    small: any;
    id: number;
}
export enum Feature {
    Crm = 0x1,
    Call = 0x2,
    Users = 0x4,
    Tenant = 0x8,
    Shop = 0x10,
    Cta = 0x20,
    Appointments = 0x40,
    Orders = 0x80,
    Integrations = 0x100,
    Journeys = 0x200,
    Notes = 0x400,
    EmailTemplates = 0x800,
    Audit = 0x1_000,
    Reports = 0x2_000,
    Tenants = 0x4_000,
    Brand = 0x8_000,
    SectionTemplate = 0x10_000,
    Product = 0x20_000,
    Media = 0x40_000,
    Tags = 0x80_000,
    CtaMessages = 0x100_000,
}

export type AccountData = {
    id: number,
    limits?: UsageInformation,
    remaining: UsageInformation,
    features: number
}

export type UsageInformation = {
    journeys: number,
    products: number,
    assets: number,
    assetbytes: number,
    users: number,
    appointments: number,
    minutes: number,
}

export type SetupOptions = {
    createAssetSource: boolean,
    createShop: boolean,
    createDepartment: boolean,
    createService: boolean,
    assignStaff: boolean,
    addCommunications: boolean,
    enableSurvey: boolean,
    addIntegrations: string[]
}

export type Account = {
    id: number;
    billingContactId: number;
    invoiceEmails: string;
    newAccount: boolean;
}

export type PagingList<T> = {
    items: T[];
    count: number;
}
type AppointmentCustomer = {
    customerId: number
}

type AppointmentStaff = {
    userId: number
}

enum MessageType {
    Chat,
    External,
    Note
}

type Message = {
    content: string,
    type: MessageType
}

type VideoCall = {

}

export type Appointment = {
    status?: AppointmentStatus,
    title: string,
    start: string,
    end?: string,
    bufferBefore: string,
    bufferAfter: string,
    overrideOpeningHours?: boolean,
    customers?: AppointmentCustomer[],
    staff?: AppointmentStaff[],
    appointmentTemplateId: number,
    departmentId: number,
    notes?: Message[]
    videoCall?: VideoCall
}

export type Service = {
    duration: string;
    name: string,
    id: number;

}
export type DepartmentService = {
    departmentId?: number,
    appointmentTemplate: Service,
    appointmentTemplateId: number,
}

export type Shop = {
    openingHours: OpeningHours[];
    bufferAfterAppointment?: number;
    bufferBeforeAppointment?: number;

}
export enum AllocationStrategy {
    None,
    //First available staff member based on assigned priority
    PriorityBasis,
    //Rotate evenly among all staff members
    RoundRobin,
    //Calculate the person with the most hours available on the given day
    LeastBusy
}

export type Department = {
    openingHours: OpeningHours[];
    name: string,
    id: number;
    staff: Staff[],
    services: DepartmentService[]
    autoAllocation: AllocationStrategy
}

export type Staff = {
    id?: number
    priority: number,
    tenantUserId: number,
    tenantUser: TenantUser,
    openingHours?: OpeningHours[],
}

export type OpeningHours = {
    start: string;
    timeZone: string;
    end: string;
    dayOfTheWeek: number;

}

export type OpeningHoursException = {
    deleted: boolean;
    start: string;
    timeZone: string;
    end: string;
    status: number;
    startDate: string;
    endDate: string;
    dayOfTheWeek: number;
}

export type Asset = {
    id: any;
    content: string
    filename: any;
}

export type ValueChanged = { target: { value: string } }